import React from 'react';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-main">
          <div className="footer-section">
            <h3 className="footer-logo">
              MAKRON VAPES<sup>®</sup>
            </h3>
            <p className="footer-disclaimer">
              Products intended for adults of legal smoking age only. Compliance with all applicable laws and regulations required.
            </p>
          </div>

          <div className="footer-section">
            <h4 className="footer-heading">Quick Links</h4>
            <nav className="footer-nav">
              <a href="/#about" className="footer-link">About</a>
              <a href="/#solutions" className="footer-link">Solutions</a>
              <a href="/#partnership" className="footer-link">Partnership</a>
              <a href="/#faq" className="footer-link">FAQ</a>
              <a href="/support" className="footer-link">Support</a>
            </nav>
          </div>

          <div className="footer-section">
            <h4 className="footer-heading">Follow Us On</h4>
            <div className="footer-social">
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
                <FaInstagram />
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook">
                <FaFacebook />
              </a>
            </div>
          </div>

          <div className="footer-section">
            <h4 className="footer-heading">Contact Info</h4>
            <address className="footer-contact">
              <p>700 Green Tree Dr.</p>
              <p>Weatherford, TX 76087</p>
              <p>info@makronvapes.com</p>
            </address>
          </div>
        </div>

        <div className="footer-divider"></div>

        <div className="footer-bottom">
          <p>© {currentYear} All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
