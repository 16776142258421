import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Features from './components/Features';
import Metrics from './components/Metrics';
import WhyUs from './components/WhyUs';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Contact from './components/Contact';
import CustomerSupport from './components/CustomerSupport';

const HomePage = () => (
  <>
    <Hero />
    <Features />
    <Metrics />
    <WhyUs />
    <FAQ />
  </>
);

const App: React.FC = () => {
  console.log('App component rendering');
  
  return (
    <div className="app">
      <Navigation />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/support" element={<CustomerSupport />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
