import React, { useState, useEffect } from 'react';
import { FaUpload, FaCheckCircle } from 'react-icons/fa';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import app from '../firebase';

// Update the FORM_ENDPOINT to the new deployment URL you get after deploying the script above
const FORM_ENDPOINT = 'https://script.google.com/macros/s/AKfycbyXx13noY_1-GQvQgQX0zq4Xu1s3cJenL_0IGpFWzeZFM9ABMKOOD9NTV0ZbtY5HRo0DA/exec';

// Add this utility function
const generateTicketId = () => {
  const timestamp = Date.now().toString(36);
  const randomStr = Math.random().toString(36).substring(2, 7);
  return `MKN-${timestamp}-${randomStr}`.toUpperCase();
};

// Add phone formatting utility
const formatPhoneNumber = (value: string) => {
  // Remove all non-digits
  const numbers = value.replace(/\D/g, '');
  
  // Don't format if less than 3 digits
  if (numbers.length < 3) return numbers;
  
  // Format as (123) 456-7890
  if (numbers.length <= 3) {
    return `(${numbers})`;
  }
  if (numbers.length <= 6) {
    return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
  }
  return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
};

interface SupportFormData {
  fullName: string;
  email: string;
  phone: string;
  cardLastFour: string;
  issue: string;
  supportFile: File | null;
}

const storage = getStorage(app);

interface SupportFormProps {
  formData: SupportFormData;
  isSubmitting: boolean;
  onSubmit: (e: React.FormEvent) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  submitStatus: 'idle' | 'success' | 'error';
}

// Separate the form into its own component for better organization
const SupportForm: React.FC<SupportFormProps> = ({
  formData,
  isSubmitting,
  onSubmit,
  onChange,
  onFileChange,
  errorMessage,
  submitStatus
}) => (
  <form className="support-form" onSubmit={onSubmit}>
    <div className="form-group">
      <label htmlFor="fullName">Full Name*</label>
      <input
        type="text"
        id="fullName"
        name="fullName"
        value={formData.fullName}
        onChange={onChange}
        placeholder="Enter your full name"
        required
        className="form-input"
        disabled={isSubmitting}
      />
    </div>

    <div className="form-row">
      <div className="form-group">
        <label htmlFor="email">Email*</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={onChange}
          placeholder="your@email.com"
          required
          className="form-input"
          disabled={isSubmitting}
        />
      </div>

      <div className="form-group">
        <label htmlFor="phone">Phone Number*</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={onChange}
          placeholder="(123) 456-7890"
          required
          pattern="^\(\d{3}\) \d{3}-\d{4}$"
          title="Please enter phone number in format: (123) 456-7890"
          className="form-input"
          disabled={isSubmitting}
        />
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="cardLastFour">Last 4 Digits of Card*</label>
      <input
        type="text"
        id="cardLastFour"
        name="cardLastFour"
        value={formData.cardLastFour}
        onChange={onChange}
        placeholder="1234"
        maxLength={4}
        pattern="\d{4}"
        required
        title="Please enter exactly 4 digits"
        className="form-input"
        disabled={isSubmitting}
      />
    </div>

    <div className="form-group">
      <label htmlFor="issue">Describe Your Issue*</label>
      <textarea
        id="issue"
        name="issue"
        value={formData.issue}
        onChange={onChange}
        placeholder="Please describe your issue in detail"
        required
        minLength={10}
        className="form-input form-textarea"
        rows={4}
        disabled={isSubmitting}
      />
    </div>

    <div className="form-group">
      <label htmlFor="supportFile">Upload Supporting Image (optional)</label>
      <div className="file-upload">
        <input
          type="file"
          id="supportFile"
          name="supportFile"
          onChange={onFileChange}
          accept="image/*"
          className="file-input"
          disabled={isSubmitting}
        />
        <div className="file-upload-button">
          <FaUpload />
          <span>{formData.supportFile ? formData.supportFile.name : 'Choose file'}</span>
        </div>
      </div>
    </div>

    {submitStatus === 'error' && (
      <div className="form-message error">
        {errorMessage || 'There was an error submitting your request. Please try again.'}
      </div>
    )}

    <button 
      type="submit" 
      className={`button button-primary ${isSubmitting ? 'loading' : ''}`}
      disabled={isSubmitting}
    >
      {isSubmitting ? 'Submitting...' : 'Submit Support Request'}
    </button>
  </form>
);

const SuccessOverlay: React.FC<{
  ticketId: string;
  onClose: () => void;
}> = ({ ticketId, onClose }) => (
  <div className="success-overlay">
    <div className="success-content">
      <div className="success-icon">
        <FaCheckCircle />
      </div>
      <h2 className="success-title">Support Request Submitted!</h2>
      <p className="success-message">
        We've received your request and will get back to you within 24 hours.
      </p>
      <p className="ticket-id">
        Your ticket ID: <strong>{ticketId}</strong>
      </p>
      <p className="ticket-note">
        Please save this ID for future reference.
      </p>
      <button 
        className="success-button"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  </div>
);

const handleImageUpload = async (file: File, ticketId: string): Promise<string> => {
  try {
    // Sanitize filename
    const fileExtension = file.name.split('.').pop();
    const safeFileName = `${ticketId}.${fileExtension}`;
    const fileName = `support-images/${safeFileName}`;
    
    const storageRef = ref(storage, fileName);
    await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error('Storage error details:', error);
    throw new Error('Failed to upload image. Please try again.');
  }
};

const CustomerSupport: React.FC = () => {
  const [formData, setFormData] = useState<SupportFormData>({
    fullName: '',
    email: '',
    phone: '',
    cardLastFour: '',
    issue: '',
    supportFile: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [showSuccessOverlay, setShowSuccessOverlay] = useState(false);
  const [lastTicketId, setLastTicketId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    if (name === 'phone') {
      // Format phone number as user types
      setFormData(prev => ({
        ...prev,
        [name]: formatPhoneNumber(value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setFormData(prev => ({
      ...prev,
      supportFile: file
    }));
  };

  const validateForm = () => {
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      throw new Error('Please enter a valid email address');
    }

    // Validate phone format
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(formData.phone)) {
      throw new Error('Please enter a valid phone number');
    }

    // Validate card number format
    const cardRegex = /^\d{4}$/;
    if (!cardRegex.test(formData.cardLastFour)) {
      throw new Error('Please enter valid last 4 digits of your card');
    }

    // Validate issue description length
    if (formData.issue.trim().length < 10) {
      throw new Error('Please provide a more detailed description of your issue');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');
    
    try {
      validateForm();
      const ticketId = generateTicketId();
      let imageUrl = '';
      
      if (formData.supportFile) {
        try {
          imageUrl = await handleImageUpload(formData.supportFile, ticketId);
        } catch (uploadError) {
          console.error('Image upload error:', uploadError);
          setErrorMessage('Failed to upload image. Please try again.');
          setSubmitStatus('error');
          setIsSubmitting(false);
          return;
        }
      }

      const formDataToSubmit = {
        ticketId,
        fullName: formData.fullName.trim(),
        email: formData.email.trim().toLowerCase(),
        phone: formData.phone,
        cardLastFour: formData.cardLastFour,
        issue: formData.issue.trim(),
        imageUrl: imageUrl || 'No image attached',
        status: 'New'
      };

      console.log('Submitting form data:', formDataToSubmit);

      await fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataToSubmit),
        mode: 'no-cors'
      });

      // Add delay to ensure submission processes
      await new Promise(resolve => setTimeout(resolve, 2000));

      setSubmitStatus('success');
      setShowSuccessOverlay(true);
      setLastTicketId(ticketId);
      
      // Reset form
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        cardLastFour: '',
        issue: '',
        supportFile: null
      });

    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred. Please try again.';
      setErrorMessage(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="customer-support">
      <div className="container">
        <div className="support-content">
          <div className="support-info">
            <p className="support-label">CUSTOMER SUPPORT</p>
            <h1 className="support-title">
              Need help with your purchase?
            </h1>
            <p className="support-description">
              All fields are required unless marked as optional. Please fill out the form below and our support team will get back to you within 24 hours.
            </p>
          </div>

          <SupportForm
            formData={formData}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onFileChange={handleFileChange}
            errorMessage={errorMessage}
            submitStatus={submitStatus}
          />
        </div>
      </div>

      {showSuccessOverlay && (
        <SuccessOverlay
          ticketId={lastTicketId}
          onClose={() => setShowSuccessOverlay(false)}
        />
      )}
    </section>
  );
};

export default CustomerSupport;
